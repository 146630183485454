.modal{
    &-wrapper{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.6);
    }
    &-window{
        position: relative;
        background: #1d1e23;
        border-radius: 8px;
        padding: 24px;
        box-sizing: 2px 2px 10px rgba(0, 0, 0, 0.3) ;;
        z-index: 100;
        min-width: 320px;
    }
    &-title{
        &-container{
            padding-bottom: 24px;
        }
    }
    &-btn-close{
        position: absolute;
        top: 14px;
        right: 14px;
    }

    &-input, &-textarea{
        width: 100%;
        background-color: #212428;
        padding: 12px 16px;
        margin-top: 8px;
        &::placeholder{
            color: #fff;
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: 0.3px;
        }
        &-label{
            color: #5d6c76;
            font-size: 12px;
            margin-bottom: 0;
        }
        &-limitchar{
            font-size: 12px;
            color: #5d6c76;
        }
    }
    &-textarea{
        min-height: 210px;
    }
    &-check{
        &-label{
            color: #5d6c76;
            font-size: 12px;
        }
    }
}