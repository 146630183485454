button{cursor: pointer;}
.ElmButton{
    // border: red 2px solid;
    border: 0;
    border-radius: 20px;
    color: #fff;
    padding: 10px 20px;
    background-color: rgb(255, 0, 0);
    font-size: 14px;
    font-weight: bold;
    transition: all 0.6s;
    text-decoration: none;
    &:hover{
        background-color: rgb(230, 226, 226);
        color: #000;
    }
    &.Black{
        background-color: rgb(0,0,0);
        &:hover{
            background-color: rgb(230, 226, 226);
            color: #000;
        }
    }
}