.Profile{
    background-color: #e4dddd;
    padding: 8px;
    border: 1px solid #e4dddd;
    border-radius: 16px;
    &-UserInfo{
        li{
            list-style: none;
        }
    }
}