.SmashStages{
    h1{
        text-align: center;
    }
    &-Stages{
        padding-top: 24px;
        display: grid;
        grid-template-columns: 33% 33% 33%;
        margin: auto;
        &-Container{
            display: flex;
            width: 100%;
        }
        &-Stage{
            width: 100%;
            max-width: 300px;
            max-height: 300px;
            h5{
                text-align: center;
            }
            img{
                width: 100%;
            }
            &-Elm{
                transition: all 0.5s;
                opacity: 0.3;
                &.active{
                    opacity: 1;
                }
            }
        }
    }
}