$md: 768px;
.Home{
    background-color: black;
    min-height: 100vh;
    display: flex;
    &-Header{
        width: 100%;
        position: absolute;
        display: flex;
        &-Content{
            width: 100%;
            display: flex;
            padding: 24px;
        }
        &-Logo{

        }
        &-Nav{
            margin: auto;
            margin-right: 0;
        }
    }
    &C{
        width: 100%;
        margin: auto;
    }
    &-Container{
        width: 100%;
        // background-color: red;
    }
    &-Content{
        // background-color: blue;
        // padding: 40px;
    }
    &-Section{
        
        &-Container{
            display: flex;
            @media (max-width: $md){
                display: block;
            }
        }
        &-1, &-2, &-3, &-4, &-5{
            width: 100%; 
            min-height: 50vh;
            display: flex;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            cursor: pointer;
            transition: all 1s;
            overflow: hidden;
            // filter: blur(4px);
            &:hover{
                // background-size: 110%;
            }
        }
        &-1, &-2{
            min-width: 50%;
        }
        &-3, &-4, &-5{
            min-width: 33%;
        }

        &-1{
           background-color: gold //url("https://www.globalbrandsmagazine.com/wp-content/uploads/2022/04/eSports.jpg");
        }
        &-2{
            background-color: green; //url("https://ca-times.brightspotcdn.com/dims4/default/82f63e6/2147483647/strip/true/crop/4532x3081+0+0/resize/1486x1010!/quality/80/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2F96%2F8e%2F3c8b047d4647859bce81b43c2536%2F1163257-sd-comiccon-sunday-004.jpg");
        }
        &-3{
            background-color: blue; //url("https://cdn.2001online.com/wp-content/uploads/2022/11/960x0.jpg");
        }
        &-4{
            background-color: red; //url("https://images.nintendolife.com/c91e6b8af98a0/fangamer.large.jpg");
        }
        &-5{
            background-color: purple;
        }
        &-Link{
            width: 100%;
            height: 100%;
            text-decoration: none;
        }
        &-Content{
            width: 100%;
            min-height: 300px;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            padding: 12px;
            display: flex;
            h3{
                color: white;
                font-size: 64px;
                text-align: center;
                margin: auto;
            }
        }
    }
}

.SuscribeModal{
    max-width: 520px;
    color: white;
}