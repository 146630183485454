.Elm-InputText{
    padding: 5px 0px;
    &-Container{
        width: 100%;
        display: flex;
    }
    &-Content{
        width: 70%;
        margin: 0 auto;
        position: relative;
    }
    &-Label{
        &-Container{
            width: 250px;
            background-color: red;
            padding: 5px 10px;
            position: absolute;
            transition: all 0.3s;
            color:#fff;
            font-weight: bold;
            left: 0;
            border-radius: 12px;
        }
    }
    &-Input{
        padding: 6.5px 0px 6.5px 25px;
        width: 350px;
        max-width: 100%;
        border-radius: 12px;
        border-color: red;
        &:focus{
            outline: none;
        }
        &-Container{
        }
        &-Msg{
            font-size: 12px;
        }
    }
}